<template>
  <svg
    id="Layer_1"
    height="512"
    viewBox="0 0 512 512"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
  >
    <path
      d="m462.84 41h-413.68a33.131 33.131 0 0 0 -33.16 33.091v238.74h480v-238.74a33.131 33.131 0 0 0 -33.16-33.091z"
      fill="#e2e4ed"
    />
    <path d="m192.57 376.141h126.86v94.86h-126.86z" fill="#4a5160" />
    <path
      d="m16 312.831v30.21a33.132 33.132 0 0 0 33.16 33.1h413.68a33.132 33.132 0 0 0 33.16-33.1v-30.21z"
      fill="#6d7486"
    />
    <ellipse cx="363.53" cy="147.691" fill="#ffd8b7" rx="35.15" ry="35.08" />
    <path
      d="m423.01 234.651a39.188 39.188 0 0 0 -37.88-30.011h-85.33l-61.01-23.259a14.649 14.649 0 0 0 -4.79-.81 15.488 15.488 0 0 0 -13.75 8.629 15.024 15.024 0 0 0 9.56 21.031l56.95 20.5a78.978 78.978 0 0 0 26.9 4.7l.01 74.511v2.89h85.51v-38.72a3.244 3.244 0 0 1 6.39-.79l9.21 36.62v2.89h28.16v-2.89z"
      fill="#3090fc"
    />
    <path
      d="m79.16 41h-30a33.131 33.131 0 0 0 -33.16 33.091v238.74h30v-238.74a33.131 33.131 0 0 1 33.16-33.091z"
      fill="#b0b5ca"
    />
    <path
      d="m46 343.041v-30.21h-30v30.21a33.132 33.132 0 0 0 33.16 33.1h30a33.132 33.132 0 0 1 -33.16-33.1z"
      fill="#4a5160"
    />
    <g fill="#284268">
      <path
        d="m363.532 188.77a41.081 41.081 0 1 0 -41.148-41.081 41.161 41.161 0 0 0 41.148 41.081zm0-70.162a29.081 29.081 0 1 1 -29.148 29.081 29.148 29.148 0 0 1 29.148-29.081z"
      />
      <path
        d="m462.842 35h-413.684a39.171 39.171 0 0 0 -39.158 39.094v268.951a39.171 39.171 0 0 0 39.158 39.094h137.411v82.861h-32.48a6 6 0 1 0 0 12h209.789a6 6 0 0 0 0-12h-38.447v-82.861h137.411a39.171 39.171 0 0 0 39.158-39.094v-268.951a39.171 39.171 0 0 0 -39.158-39.094zm-413.684 12h413.684a27.157 27.157 0 0 1 27.158 27.094v232.735h-41.68l-19.5-73.661a45.048 45.048 0 0 0 -43.7-34.532h-84.22l-59.975-22.859c-.056-.021-.112-.042-.169-.061a20.882 20.882 0 0 0 -17.937 2.077 21.93 21.93 0 0 0 -2.9 2.154l-36.861-40.282a6 6 0 0 0 -8.853 8.1l39.195 42.835a20.857 20.857 0 0 0 -.477 2.515c0 .039-.01.078-.014.116a21 21 0 0 0 15.1 22.729l56.719 20.423a85.1 85.1 0 0 0 22.934 4.841l.011 65.609h-285.673v-232.739a27.157 27.157 0 0 1 27.158-27.094zm371.026 259.829-8.792-34.975a9.243 9.243 0 0 0 -18.207 2.254v32.721h-73.516l-.012-71.4a6 6 0 0 0 -6-6 73.205 73.205 0 0 1 -24.868-4.341l-56.948-20.506q-.25-.09-.509-.158a8.954 8.954 0 0 1 -6.5-9.88 8.827 8.827 0 0 1 .87-2.814l.022-.049a9.374 9.374 0 0 1 3.429-3.7 8.936 8.936 0 0 1 7.594-.959l60.911 23.216a6 6 0 0 0 2.137.393h85.328a33.072 33.072 0 0 1 32.054 25.442c.009.035.019.072.028.108l18.7 70.643zm-12.374 0h-2.625v-10.446zm-94.379 158.171h-114.862v-82.861h114.862zm149.411-94.861h-413.684a27.157 27.157 0 0 1 -27.158-27.094v-24.216h468v24.216a27.157 27.157 0 0 1 -27.158 27.094z"
      />
      <path d="m184.577 104.509h83.88a6 6 0 0 0 0-12h-83.88a6 6 0 0 0 0 12z" />
      <path d="m163.1 104.509h.317a6 6 0 0 0 0-12h-.317a6 6 0 0 0 0 12z" />
      <path d="m127.308 104.509h14a6 6 0 0 0 0-12h-14a6 6 0 0 0 0 12z" />
      <path d="m64.457 104.509h38.965a6 6 0 0 0 0-12h-38.965a6 6 0 1 0 0 12z" />
      <path d="m268.457 146.429h-.068a6.034 6.034 0 1 0 .068 0z" />
      <path
        d="m245.577 146.429h-29.712a6 6 0 0 0 0 12h29.712a6 6 0 0 0 0-12z"
      />
      <path d="m64.457 158.429h89.425a6 6 0 0 0 0-12h-89.425a6 6 0 1 0 0 12z" />
      <path
        d="m186.416 215.709h15.762a6 6 0 0 0 0-12h-15.762a6 6 0 0 0 0 12z"
      />
      <path
        d="m163.308 209.709a6 6 0 0 0 -6-6h-60.355a6 6 0 0 0 0 12h60.355a6 6 0 0 0 6-6z"
      />
      <path d="m64.457 215.709h9.684a6 6 0 1 0 0-12h-9.684a6 6 0 1 0 0 12z" />
      <path d="m253.383 268.65h15.074a6 6 0 0 0 0-12h-15.074a6 6 0 0 0 0 12z" />
      <path d="m230.337 268.65h.233a6 6 0 0 0 0-12h-.233a6 6 0 0 0 0 12z" />
      <path
        d="m111.194 262.65a6 6 0 0 0 6 6h91.759a6 6 0 0 0 0-12h-91.759a6 6 0 0 0 -6 6z"
      />
      <path d="m64.457 268.65h29.381a6 6 0 0 0 0-12h-29.381a6 6 0 1 0 0 12z" />
    </g>
  </svg>
</template>

<script>
export default {
    name: 'training-icon',
}
</script>
