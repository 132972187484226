<template>
  <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.327 512.327"
    style="enable-background: new 0 0 512.327 512.327"
    xml:space="preserve"
  >
    <g>
      <path
        style="fill: #ffd15c"
        d="M391.395,112.327c0,0-2.133,8.533-3.2,13.867c-1.067,6.4-4.267,25.6-16,35.2l-13.867,33.067
		l-37.333-10.667c0,0,8.533-41.6,9.6-48c1.067-6.4-2.133-21.333-1.067-37.333C330.595,83.527,387.128,88.86,391.395,112.327z"
      />
      <path
        style="fill: #ffd15c"
        d="M391.395,112.327c0,0-2.133,8.533-3.2,13.867c-1.067,6.4-4.267,25.6-16,35.2v38.4
		c-19.2,60.8-69.333,44.8-50.133-16c0,0,8.533-41.6,9.6-48c1.067-6.4-2.133-21.333-1.067-37.333
		C330.595,83.527,387.128,88.86,391.395,112.327z"
      />
    </g>
    <path
      style="fill: #f05540"
      d="M330.595,96.327l-8.533-9.6c0,0,7.467-29.867,32-33.067s45.867,13.867,44.8,40.533
	c0,0,2.133,6.4-1.067,10.667S344.461,109.127,330.595,96.327z"
    />
    <path
      style="fill: #f3705a"
      d="M322.061,86.727c0,0,5.333,5.333,12.8,7.467s49.067,4.267,57.6,6.4
	c8.533,2.133,5.333-4.267,6.4-7.467c0,0,3.2,12.8,3.2,17.067c0,4.267-3.2,4.267-6.4,4.267c-3.2,0-38.4-3.2-51.2-6.4
	c-12.8-3.2-13.867-2.133-17.067-3.2c-4.267-1.067-8.533-7.467-8.533-10.667C317.795,92.06,322.061,86.727,322.061,86.727z"
    />
    <path
      style="fill: #f7b64c"
      d="M371.128,162.46c0,0-5.333,5.333-16,4.267s-23.467-17.067-25.6-29.867
	c0,0-2.133,17.067,13.867,36.267c5.333,6.4,11.733,10.667,17.067,13.867L371.128,162.46z"
    />
    <path
      style="fill: #ced6e0"
      d="M343.395,170.994c0,0,9.6,14.933,6.4,28.8c0,0,3.2-12.8,3.2-18.133
	c0-5.333,8.533-14.933,8.533-14.933l12.8,22.4l-29.867,100.267l-36.267-8.533l8.533-110.933L343.395,170.994z"
    />
    <path
      style="fill: #415a6b"
      d="M288.995,511.26c1.067-6.4,7.467-27.733,11.733-39.467c5.333-12.8,12.8-30.933,12.8-30.933
	s2.133,1.067,5.333,11.733c3.2,9.6,14.933,50.133,17.067,58.667h57.6c4.267-22.4,3.2-99.2,3.2-99.2l-51.2-65.067
	c0,0-81.067,11.733-98.133,51.2c-16,36.267-10.667,102.4-9.6,114.133h51.2V511.26z"
    />
    <ellipse
      transform="matrix(-0.096 -0.9954 0.9954 -0.096 245.0383 455.7942)"
      style="fill: #ffd15c"
      cx="329.494"
      cy="116.626"
      rx="10.667"
      ry="5.333"
    />
    <path
      style="fill: #344a5e"
      d="M311.395,161.394c0,0-2.133-1.067-9.6,3.2c-8.533,4.267-23.467,5.333-27.733,5.333
	c-4.267,1.067-4.267,1.067-4.267,1.067s21.333,43.733,17.067,66.133c-4.267,22.4-46.933,137.6-52.267,151.467
	c-4.267,13.867-6.4,20.267-6.4,20.267s24.533,19.2,35.2,22.4c10.667,3.2,19.2,5.333,26.667-10.667s33.067-83.2,36.267-112
	c2.133-29.867-9.6-141.867-9.6-141.867L311.395,161.394z"
    />
    <path
      style="fill: #ffd15c"
      d="M296.461,313.927c0,0-14.933-2.133-19.2-18.133C274.061,278.727,296.461,298.994,296.461,313.927z"
    />
    <path
      style="fill: #e8eae9"
      d="M376.461,156.06c0,0-4.267,7.467-14.933,9.6l-1.067,25.6l16-26.667V156.06z"
    />
    <path
      style="fill: #344a5e"
      d="M420.195,426.994c0,0-3.2,5.333-21.333,8.533c-18.133,3.2-28.8,4.267-28.8,4.267
	s-17.067,3.2-21.333-8.533c-4.267-11.733-23.467-77.867-23.467-107.733c0-30.933,57.6-156.8,57.6-156.8s10.667,5.333,14.933,9.6
	s3.2,3.2,7.467,7.467c4.267,4.267,18.133,8.533,18.133,8.533s-18.133,10.667-26.667,35.2
	C387.128,250.994,420.195,426.994,420.195,426.994z"
    />
    <g>
      <path
        style="fill: #415a6b"
        d="M430.861,225.394c0,22.4-3.2,74.667-14.933,91.733c-5.333,7.467-8.533,12.8-11.733,16
		c-3.2,3.2-6.4,4.267-9.6,5.333c-4.267,1.067-41.6-3.2-69.333-6.4c-17.067-2.133-29.867-3.2-29.867-3.2l-2.133-41.6
		c0,0,22.4,1.067,40.533,1.067c8.533,0,14.933,0,18.133,0c9.6,0,21.333-8.533,25.6-9.6c3.2-1.067,3.2-14.933,7.467-24.533
		c3.2-9.6,11.733-26.667,11.733-27.733l0,0c8.533-23.467,26.667-35.2,26.667-35.2S430.861,201.927,430.861,225.394z"
      />
      <path
        style="fill: #415a6b"
        d="M376.461,158.194c0,0-4.267,9.6-6.4,13.867c-2.133,4.267-13.867,26.667-17.067,33.067
		s-11.733,21.333-20.267,54.4c-8.533,34.133-7.467,64-7.467,64s9.6-44.8,25.6-73.6s37.333-59.733,37.333-59.733l-8.533-5.333
		l8.533-1.067c0,0,0-8.533-3.2-13.867c-3.2-5.333-6.4-7.467-7.467-9.6C376.461,158.194,376.461,158.194,376.461,158.194z"
      />
    </g>
    <path
      style="fill: #e8eae9"
      d="M329.528,142.194c0,0,3.2,20.267,13.867,28.8l-2.133,3.2l-4.267-2.133c0,0-9.6,6.4-9.6,19.2
	c0,0-5.333-21.333-5.333-28.8C322.061,156.06,326.328,145.394,329.528,142.194z"
    />
    <g>
      <path
        style="fill: #415a6b"
        d="M327.395,146.46c0,0-4.267,3.2-7.467,8.533c-3.2,5.333-8.533,6.4-8.533,6.4l-6.4,10.667l6.4,3.2
		l-10.667,2.133c0,0,16,75.733,20.267,94.933c5.333,18.133,4.267,36.267,4.267,36.267s7.467-55.467,4.267-86.4
		C327.395,189.127,323.128,158.194,327.395,146.46z"
      />
      <path
        style="fill: #415a6b"
        d="M359.395,297.927c0,0-13.867,1.067-32,2.133c-20.267,1.067-44.8,1.067-59.733-1.067
		c-3.2-1.067-6.4-1.067-8.533-2.133c-22.4-8.533-18.133-42.667-18.133-42.667s14.933-58.667,20.267-70.4
		c5.333-11.733,9.6-11.733,9.6-11.733s21.333,43.733,17.067,66.133c-1.067,3.2-2.133,8.533-4.267,14.933l0,0
		c0,0,9.6,4.267,20.267,7.467c10.667,3.2,9.6,0,16,0c1.067,0,3.2,0,6.4,0c11.733,0,33.067,2.133,33.067,2.133
		C363.661,270.194,359.395,297.927,359.395,297.927z"
      />
    </g>
    <path
      style="fill: #ffd15c"
      d="M293.261,259.527c0,0-4.267-19.2-5.333-20.267c-1.067-2.133-2.133-2.133-3.2,0l-3.2-5.333
	c0,0-4.267-1.067-3.2,4.267c0,0-3.2-1.067-3.2,3.2s2.133,6.4,2.133,6.4s-3.2,1.067-1.067,8.533c2.133,7.467,2.133,9.6,2.133,9.6
	L293.261,259.527z"
    />
    <g>
      <path
        style="fill: #f7b64c"
        d="M285.795,242.46c-1.067-2.133-1.067-3.2-1.067-3.2l0,0
		C284.728,239.26,284.728,240.327,285.795,242.46c0,1.067,0,2.133,1.067,3.2c0,1.067,1.067,2.133,1.067,3.2
		c0,1.067,0,1.067,1.067,2.133c0,1.067,1.067,1.067,1.067,1.067c0,1.067,1.067,2.133,1.067,3.2c0,0,0,1.067,1.067,1.067v-1.067l0,0
		c0-1.067-1.067-2.133-1.067-3.2c0,0,0-1.067-1.067-1.067c0-1.067,0-1.067-1.067-2.133c0-1.067-1.067-2.133-1.067-3.2
		C286.861,243.527,286.861,242.46,285.795,242.46z"
      />
      <path
        style="fill: #f7b64c"
        d="M279.395,237.127C279.395,237.127,279.395,238.194,279.395,237.127
		c1.067,1.067,1.067,2.133,2.133,3.2c0,1.067,1.067,2.133,1.067,3.2c0,1.067,0,1.067,1.067,2.133c0,1.067,0,1.067,1.067,2.133
		c0,1.067,1.067,2.133,1.067,3.2c0,1.067,1.067,2.133,1.067,3.2c1.067,2.133,1.067,3.2,1.067,3.2s-1.067-1.067-1.067-3.2
		c0-1.067-1.067-2.133-1.067-3.2c0-1.067-1.067-2.133-1.067-3.2c0-1.067,0-1.067-1.067-2.133c0-1.067,0-1.067-1.067-2.133
		c0-1.067-1.067-2.133-1.067-3.2C279.395,238.194,279.395,237.127,279.395,237.127z"
      />
      <path
        style="fill: #f7b64c"
        d="M277.261,247.794C277.261,247.794,278.328,247.794,277.261,247.794c1.067,0,1.067,0,1.067,0h1.067
		l0,0l0,0l0,0v1.067c0,1.067,0,2.133,1.067,2.133c0,1.067,0,2.133,1.067,2.133c0,1.067,0,1.067,0,2.133s0,2.133,0,2.133
		s0-1.067-1.067-2.133c0-1.067,0-1.067-1.067-2.133c0-1.067,0-2.133-1.067-2.133c0-1.067,0-2.133-1.067-2.133V247.794
		L277.261,247.794L277.261,247.794L277.261,247.794C279.395,247.794,278.328,247.794,277.261,247.794
		C278.328,247.794,277.261,247.794,277.261,247.794z"
      />
      <path
        style="fill: #f7b64c"
        d="M414.861,312.86c0,0,0-1.067-2.133-3.2c-2.133-1.067-10.667-6.4-13.867-9.6
		c-3.2-3.2-8.533-7.467-11.733-9.6c-3.2-2.133-5.333-1.067-10.667-1.067c-1.067,0-2.133,0-3.2,0v16h5.333
		c1.067-1.067,1.067-1.067,2.133-2.133c2.133-1.067,6.4,0,7.467,0c0,0,14.933,8.533,17.067,8.533c0,0,8.533,7.467,13.867,3.2
		L414.861,312.86z"
      />
    </g>
    <path
      style="fill: #66c6b9"
      d="M372.195,353.394l83.2-16c0,0,58.667-22.4,43.733-89.6l-117.333,66.133L274.061,332.06l-3.2,48
	L372.195,353.394z"
    />
    <path
      style="fill: #ffd15c"
      d="M327.395,291.527c0,0,23.467,12.8,27.733,14.933c4.267,2.133,9.6,4.267,17.067,4.267
	c7.467,0,19.2,4.267,22.4,5.333c4.267,1.067,7.467-2.133,4.267-5.333c-3.2-4.267-7.467-5.333-7.467-5.333l-12.8-3.2
	c0,0,3.2-3.2,5.333-4.267c2.133-1.067,6.4,0,7.467,0c0,0,11.733,8.533,16,10.667c0,0,8.533,10.667,14.933,3.2l-4.267-3.2
	c0,0-1.067-1.067-3.2-2.133c-2.133-1.067-9.6-7.467-13.867-10.667c-3.2-3.2-8.533-8.533-11.733-10.667
	c-3.2-2.133-4.267-1.067-10.667-1.067c-5.333,0-17.067,0-21.333-1.067c-4.267-1.067-17.067-5.333-17.067-5.333l-16,3.2
	L327.395,291.527z"
    />
    <g>
      <path
        style="fill: #415a6b"
        d="M133.261,511.26l4.267-14.933c0,6.4,1.067,11.733,1.067,14.933h82.133
		c1.067-4.267,2.133-16,0-33.067c-3.2-22.4-22.4-76.8-22.4-76.8l3.2-21.333l-70.4,2.133c0,0-72.533,23.467-80,54.4
		c-6.4,27.733-1.067,67.2,0,75.733h82.133V511.26z"
      />
      <path
        style="fill: #415a6b"
        d="M133.261,511.26l4.267-14.933c0,6.4,1.067,11.733,1.067,14.933h82.133
		c1.067-4.267,2.133-16,0-33.067c-3.2-22.4-24.533-103.467-24.533-103.467l-65.067,6.4c0,0-72.533,23.467-80,54.4
		c-6.4,27.733-1.067,67.2,0,75.733L133.261,511.26L133.261,511.26z"
      />
    </g>
    <g>
      <path
        style="fill: #344a5e"
        d="M183.395,149.66c0,0,11.733,10.667,16,21.333c4.267,10.667,3.2,16,5.333,18.133
		c2.133,3.2,5.333,4.267,6.4,7.467c1.067,3.2,14.933,24.533,14.933,24.533s12.8,5.333,14.933,9.6c2.133,4.267,2.133,6.4,2.133,6.4
		s3.2-1.067,6.4,1.067c3.2,2.133,5.333,2.133,8.533,4.267c4.267,1.067,4.267,0,5.333,3.2c2.133,2.133,30.933,16,40.533,19.2
		c9.6,3.2,37.333,12.8,37.333,12.8s-12.8,2.133-9.6,44.8c0,0-17.067-6.4-32-12.8c-16-6.4-30.933-8.533-40.533-13.867
		c-8.533-5.333-17.067-9.6-32-14.933c-13.867-5.333-23.467-11.733-23.467-11.733S179.128,154.994,183.395,149.66z"
      />
      <path
        style="fill: #344a5e"
        d="M211.128,423.794l-2.133-1.067c-6.4-25.6-12.8-49.067-12.8-49.067h-4.267
		c6.4-24.533,4.267-70.4,0-91.733c-6.4-40.533-13.867-83.2-24.533-101.333c-10.667-18.133-22.4-39.467-22.4-39.467
		s28.8,0,38.4,7.467c0,0,12.8,13.867,18.133,37.333c5.333,22.4,5.333,72.533,5.333,80c0,7.467,2.133,61.867,5.333,76.8
		c2.133,11.733,6.4,54.4,8.533,73.6C220.728,421.66,216.461,424.86,211.128,423.794z"
      />
    </g>
    <path
      style="fill: #ffd15c"
      d="M200.461,79.26c0,0-5.333,8.533-6.4,9.6c0,0,0,2.133,2.133,6.4c2.133,4.267,4.267,9.6,4.267,9.6
	s1.067,4.267-3.2,5.333c-4.267,1.067-6.4,3.2-6.4,4.267c0,1.067,3.2,4.267,0,6.4c0,0,2.133,2.133,0,5.333
	c-2.133,3.2-6.4,4.267-6.4,9.6c-1.067,4.267-2.133,10.667-16,9.6c-13.867-1.067-18.133,1.067-20.267,5.333s-5.333,19.2-1.067,35.2
	s4.267,16,4.267,16l-56.533-66.133c0,0,10.667-13.867,12.8-25.6s8.533-54.4,8.533-54.4L200.461,79.26z"
    />
    <path
      style="fill: #ced6e0"
      d="M181.261,221.127c-1.067-2.133,0-5.333,0-7.467c-7.467-14.933-13.867-28.8-17.067-44.8
	c0-1.067,0-1.067,0-2.133l-6.4-7.467l-12.8,19.2c0,0-2.133-4.267-7.467-6.4c-4.267-2.133-30.933-22.4-30.933-22.4l-19.2,1.067
	l40.533,236.8c0,0,0,0,29.867,0s40.533-9.6,40.533-9.6S199.395,286.194,181.261,221.127z"
    />
    <path
      style="fill: #415a6b"
      d="M212.195,342.727c0,0-11.733-52.267-19.2-69.333c-7.467-18.133-18.133-70.4-24.533-82.133
	s-20.267-35.2-20.267-35.2l2.133-8.533c1.067,0,2.133-1.067,3.2-1.067c6.4,12.8,32,61.867,43.733,107.733
	C211.128,305.394,212.195,342.727,212.195,342.727z"
    />
    <path
      style="fill: #344a5e"
      d="M90.595,132.594c0,0-6.4,6.4-14.933,10.667s-11.733,3.2-19.2,7.467s-30.933,19.2-36.267,30.933
	c0,0,10.667-13.867,23.467-8.533c13.867,5.333,37.333,57.6,39.467,73.6c2.133,16-42.667,128-54.4,165.333s-8.533,65.067,3.2,72.533
	s61.867,43.733,89.6,12.8c27.733-30.933,37.333-154.667,37.333-154.667s2.133-55.467-13.867-90.667S90.595,132.594,90.595,132.594z"
    />
    <path
      style="fill: #e8eae9"
      d="M93.795,128.327l6.4-5.333c0,0,7.467,19.2,16,27.733c8.533,8.533,22.4,20.267,22.4,20.267
	s-5.333,1.067-10.667-1.067l-10.667,11.733C117.261,181.66,92.728,130.46,93.795,128.327z"
    />
    <path
      style="fill: #344a5e"
      d="M104.461,114.46c0,0,13.867-5.333,22.4-24.533c7.467-19.2,19.2-8.533,22.4-5.333
	c3.2,4.267,4.267,14.933,2.133,20.267h8.533c0,0,0-22.4,7.467-27.733c7.467-5.333,32,5.333,32,5.333s8.533-3.2,5.333-10.667
	c-3.2-7.467-76.8-34.133-86.4-29.867c-9.6,5.333-21.333,14.933-19.2,23.467s6.4,25.6,6.4,34.133
	C105.528,109.127,103.395,112.327,104.461,114.46z"
    />
    <ellipse
      transform="matrix(-0.0338 -0.9994 0.9994 -0.0338 55.5954 231.4241)"
      style="fill: #ffd15c"
      cx="139.668"
      cy="88.837"
      rx="16"
      ry="10.666"
    />
    <path
      style="fill: #f05540"
      d="M88.461,51.527l-1.067-5.333c0,0,7.467-41.6,50.133-45.867s67.2,34.133,67.2,61.867
	c0,0-4.267,7.467-4.267,8.533C199.395,70.727,123.661,79.26,88.461,51.527z"
    />
    <path
      style="fill: #f3705a"
      d="M87.395,46.194c0,0,54.4,22.4,96,20.267c0,0,17.067,0,21.333-5.333c0,0-1.067,2.133,0,5.333
	c1.067,3.2,16,11.733,16,13.867c0,3.2-6.4,7.467-18.133,4.267c-11.733-3.2-25.6-2.133-37.333-4.267
	c-10.667-2.133-55.467-9.6-77.867-19.2c-3.2,0-4.267-4.267-3.2-6.4C85.261,51.527,86.328,49.394,87.395,46.194z"
    />
    <path
      style="fill: #415a6b"
      d="M154.595,285.127c-6.4-19.2-19.2-71.467-29.867-100.267s-24.533-46.933-24.533-52.267
	c-1.067-5.333,3.2-16,3.2-16s-11.733,14.933-16,19.2c0,0,0,0,0,1.067c-2.133,3.2-3.2,6.4-2.133,10.667
	c2.133,6.4,5.333,12.8,5.333,12.8l14.933,8.533l-9.6,8.533c0,0,1.067,1.067,13.867,17.067s34.133,69.333,42.667,88.533
	c8.533,19.2,7.467,59.733,7.467,59.733S160.995,304.327,154.595,285.127z"
    />
    <path
      id="SVGCleanerId_0"
      style="fill: #e8eae9"
      d="M152.461,146.46c0,0,9.6,16,9.6,19.2c0,3.2-7.467,8.533-7.467,8.533
	s-5.333,4.267-6.4,9.6c-1.067,5.333,1.067,16,1.067,16c-2.133-9.6-4.267-16-5.333-24.533
	C144.995,175.26,141.795,149.66,152.461,146.46z"
    />
    <g>
      <path
        id="SVGCleanerId_0_1_"
        style="fill: #e8eae9"
        d="M152.461,146.46c0,0,9.6,16,9.6,19.2c0,3.2-7.467,8.533-7.467,8.533
		s-5.333,4.267-6.4,9.6c-1.067,5.333,1.067,16,1.067,16c-2.133-9.6-4.267-16-5.333-24.533
		C144.995,175.26,141.795,149.66,152.461,146.46z"
      />
    </g>
    <path
      style="fill: #f7b64c"
      d="M158.861,145.394c0,0-18.133-9.6-24.533-28.8c0,0,2.133,19.2,13.867,34.133v-1.067
	C149.261,147.527,151.395,146.46,158.861,145.394L158.861,145.394z"
    />
    <path
      style="fill: #e8eae9"
      d="M71.395,448.327l119.467-27.733l144-129.067c0,0,51.2,61.867,120.533,45.867l-309.333,137.6
	C146.061,474.994,107.661,485.66,71.395,448.327z"
    />
    <path
      style="fill: #ffd15c"
      d="M128.995,373.66l19.2,11.733c0,0,8.533,2.133,14.933,4.267c4.267,1.067,9.6,5.333,12.8,7.467
	c1.067,1.067,2.133,2.133,2.133,4.267l1.067,6.4c0,0,9.6,7.467,12.8,10.667c3.2,3.2,7.467,3.2,6.4,8.533
	c0,0,2.133,1.067,2.133,4.267c0,3.2-4.267,5.333-4.267,5.333s-2.133,3.2-6.4,4.267c-4.267,1.067-13.867,2.133-13.867,2.133
	s-3.2,2.133-9.6,3.2c-6.4,1.067-12.8-1.067-18.133-2.133c-6.4-1.067-13.867-4.267-14.933-7.467c-1.067-3.2-4.267-7.467-5.333-10.667
	c-1.067-3.2-1.067-7.467-1.067-7.467s-4.267-5.333-7.467-7.467c-3.2-2.133-23.467-17.067-23.467-17.067
	S117.261,365.127,128.995,373.66z"
    />
    <g>
      <path
        style="fill: #f7b64c"
        d="M199.395,428.06c0,0-1.067,2.133-2.133,3.2c-1.067,1.067-4.267,2.133-6.4,1.067
		c-2.133-1.067-10.667-6.4-13.867-7.467c-3.2-2.133-7.467-6.4-10.667-7.467c-2.133-2.133-6.4-3.2-6.4-3.2s2.133,3.2,4.267,4.267
		c2.133,1.067,7.467,3.2,10.667,5.333c3.2,2.133,8.533,5.333,10.667,6.4c2.133,1.067,5.333,3.2,8.533,3.2
		C197.261,433.394,198.328,430.194,199.395,428.06z"
      />
      <path
        style="fill: #f7b64c"
        d="M197.261,437.66c0,0-1.067,1.067-5.333,2.133c-3.2,1.067-9.6,0-11.733,0
		c-2.133,0-4.267,1.067-7.467-1.067c-3.2-1.067-13.867-8.533-14.933-9.6c-1.067-1.067-6.4-2.133-6.4-2.133s3.2,2.133,7.467,4.267
		s9.6,5.333,11.733,6.4s4.267,3.2,8.533,3.2C183.395,439.794,192.995,441.927,197.261,437.66z"
      />
      <path
        style="fill: #f7b64c"
        d="M168.461,444.06c-5.333-2.133-12.8-5.333-14.933-6.4c-3.2-1.067-8.533-3.2-7.467-2.133
		s9.6,5.333,12.8,6.4c3.2,1.067,10.667,5.333,16,3.2c1.067,0,1.067-1.067,1.067-1.067
		C175.928,445.127,173.795,446.194,168.461,444.06z"
      />
      <path
        style="fill: #f7b64c"
        d="M163.128,392.86C163.128,392.86,164.195,392.86,163.128,392.86c1.067,1.067,1.067,1.067,1.067,2.133
		c0,0,0,1.067,1.067,1.067c0,1.067,1.067,2.133,2.133,3.2l1.067,1.067c0,0,1.067,1.067,2.133,1.067
		c1.067,1.067,2.133,2.133,3.2,2.133c1.067,1.067,2.133,1.067,3.2,2.133c1.067,1.067,2.133,1.067,2.133,1.067l1.067,1.067
		c0,0,0,0-1.067,0c-1.067,0-1.067-1.067-2.133-1.067c-1.067-1.067-2.133-1.067-3.2-2.133c-1.067,0-1.067-1.067-2.133-1.067
		c-1.067,0-1.067-1.067-2.133-1.067s-1.067-1.067-2.133-1.067s-1.067-1.067-1.067-1.067c-1.067-1.067-1.067-2.133-2.133-3.2
		c0-1.067,0-1.067,0-1.067C164.195,393.927,164.195,393.927,163.128,392.86L163.128,392.86z"
      />
    </g>
    <path
      style="fill: #415a6b"
      d="M135.395,376.86c0,0-11.733,2.133-19.2,14.933c-6.4,13.867-7.467,34.133-7.467,34.133
	s-34.133-16-40.533-23.467c-3.2-4.267-16-17.067-25.6-29.867c-7.467-9.6-14.933-19.2-17.067-24.533
	c-4.267-11.733-4.267-38.4-3.2-43.733c1.067-4.267-7.467-34.133-10.667-44.8c-3.2-10.667,3.2-39.467,3.2-39.467
	s-1.067-26.667,5.333-38.4l0,0c0,0,10.667-13.867,23.467-8.533c13.867,5.333,37.333,57.6,39.467,73.6
	c2.133,8.533-2.133,13.867-2.133,25.6s-6.4,22.4-3.2,33.067c3.2,11.733,0,17.067,3.2,22.4c2.133,4.267,18.133,20.267,26.667,25.6
	C115.128,358.727,135.395,376.86,135.395,376.86z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {
    name: 'construction-icon',
}
</script>
