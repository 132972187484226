<template>
  <svg
    height="512"
    viewBox="0 0 128 128"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="number">
      <text
        fill="#24253a"
        font-family="MyriadPro-Regular, Myriad Pro"
        font-size="40"
        letter-spacing=".002em"
        transform="translate(43.419 -54.265)"
      >
        10
        <tspan x="0" y="228">20</tspan>
        <tspan x="0" y="456">30</tspan>
        <tspan x="0" y="684">40</tspan>
        <tspan x="0" y="912">50</tspan>
        <tspan x="0" y="1140">60</tspan>
        <tspan x="0" y="1368">70</tspan>
        <tspan x="0" y="1596">80</tspan>
        <tspan x="0" y="1824">90</tspan>
        <tspan x="-10.299" y="2052">100</tspan>
        <tspan x="-10.299" y="2280">110</tspan>
        <tspan x="-10.299" y="2508">120</tspan>
        <tspan x="-10.299" y="2736">130</tspan>
        <tspan x="-10.299" y="2964">140</tspan>
        <tspan x="-10.299" y="3192">150</tspan>
        <tspan x="-10.299" y="3420">160</tspan>
        <tspan x="-10.299" y="3648">170</tspan>
        <tspan x="-10.299" y="3876">180</tspan>
        <tspan x="-10.299" y="4104">190</tspan>
        <tspan x="-10.299" y="4332">200</tspan>
        <tspan x="-10.299" y="4560">210</tspan>
        <tspan x="-10.299" y="4788">220</tspan>
        <tspan x="-10.299" y="5016">230</tspan>
        <tspan x="-10.299" y="5244">240</tspan>
        <tspan x="-10.299" y="5472">250</tspan>
      </text>
    </g>
    <g id="icon">
      <rect
        fill="#f6b863"
        height="37.806"
        rx=".84"
        transform="matrix(.957 -.292 .292 .957 -15.624 24.832)"
        width="37.806"
        x="56.601"
        y="45.934"
      />
      <path
        d="m73.304 45.746 2.601 8.534-1.333 2.831-2.223-2.625-1.072 4.78-1.179-3.866-1.603 3.01-3.123-10.247z"
        fill="#eaa14e"
      />
      <path
        d="m63.681 64.54h27.623v13.644h-27.623z"
        fill="#eaa14e"
        transform="matrix(.957 -.292 .292 .957 -17.44 25.695)"
      />
      <g fill="#f6b863">
        <path
          d="m64.818 67.156h23.171v1.26h-23.171z"
          transform="matrix(.957 -.292 .292 .957 -16.444 25.222)"
        />
        <path
          d="m65.908 70.732h23.171v1.26h-23.171z"
          transform="matrix(.957 -.292 .292 .957 -17.44 25.695)"
        />
        <path
          d="m67.214 75.762h13.196v1.26h-13.196z"
          transform="matrix(.957 -.292 .292 .957 -19.066 24.84)"
        />
      </g>
      <path
        d="m58.482 94.862a2.694 2.694 0 0 1 -2.582-1.938l-18.4-62.937-9.408 2.561a2.693 2.693 0 1 1 -1.415-5.2l11.957-3.248a2.692 2.692 0 0 1 3.293 1.844l18.373 62.856 39.533-12.047a2.694 2.694 0 1 1 1.571 5.153l-42.136 12.839a2.674 2.674 0 0 1 -.786.117z"
        fill="#eeefee"
      />
      <path
        d="m70.965 92.167a11.833 11.833 0 1 1 -11.833-11.832 11.833 11.833 0 0 1 11.833 11.832z"
        fill="#475f6c"
      />
      <path
        d="m59.132 97.716a5.549 5.549 0 1 1 5.549-5.548 5.555 5.555 0 0 1 -5.549 5.548z"
        fill="#bbbbcd"
      />
    </g>
  </svg>
</template>

<script>
export default {
    name: 'procurement-icon',
}
</script>
