<template>
  <svg
    height="512"
    viewBox="0 0 64 64"
    width="512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Maintenance_tools" data-name="Maintenance tools">
      <path
        d="m33.81 10.98-1.42-2.83-6.36-3.53-1.41 1.41 3.53 6.36 2.83 1.42 14.141 14.141 2.83-2.831z"
        fill="#ff6243"
      />
      <path
        d="m26.03 6.62 6.36 3.53 1.42 2.83 13.141 13.14 1-1-14.141-14.14-1.42-2.83-6.36-3.53-1.41 1.41.714 1.286z"
        fill="#ff5023"
      />
      <path
        d="m32.588 24.694a8.006 8.006 0 0 0 -8.563 11.038l5.247-5.247 3.536.707.707 3.536-5.247 5.247a8.006 8.006 0 0 0 11.038-8.563 4.133 4.133 0 0 1 1.1-3.571l7.435-7.441a4.134 4.134 0 0 1 3.571-1.1 8.006 8.006 0 0 0 8.563-11.032l-5.247 5.247-3.536-.707-.707-3.536 5.247-5.247a8.006 8.006 0 0 0 -11.038 8.563 4.134 4.134 0 0 1 -1.1 3.571l-2.3 2.3.006.006-.006-.006-2.83 2.83.006.006-.006-.006-2.3 2.3a4.133 4.133 0 0 1 -3.576 1.105z"
        fill="#ff9811"
      />
      <path
        d="m59.975 8.268-1.545 1.545a7.953 7.953 0 0 1 -6.848 9.508 8 8 0 0 0 8.393-11.053z"
        fill="#ee8700"
      />
      <path
        d="m49.412 19.306a4.134 4.134 0 0 0 -3.571 1.1l-7.441 7.435a4.133 4.133 0 0 0 -1.1 3.571 7.965 7.965 0 0 1 -6.952 9.116 7.99 7.99 0 0 0 8.952-9.116 4.133 4.133 0 0 1 1.1-3.571l7.441-7.441a3.906 3.906 0 0 1 2.084-1.049c-.171-.013-.343-.02-.513-.045z"
        fill="#ee8700"
      />
      <path
        d="m53.021 38.678a4 4 0 0 0 5.657-5.657l-6.486-6.485-5.656 5.656z"
        fill="#005ece"
      />
      <path
        d="m46.536 32.192 5.656-5.656a2 2 0 0 0 -2.828-2.829l-5.657 5.657a2 2 0 1 0 2.829 2.828z"
        fill="#006df0"
      />
      <path d="m11 61h8v-3-12-3h-8v2 14z" fill="#006df0" />
      <path d="m3 45h8v14h-8z" fill="#004fac" />
      <path
        d="m60.28 45.4a2.994 2.994 0 0 0 -4.09-1.1l-10.67 6.16h-.005a2.987 2.987 0 0 0 -2.094-2.06l-14.92-4a6 6 0 0 0 -3.008-.025l-6.493 1.625v12l2.733 1.367a6.007 6.007 0 0 0 2.683.633h14.977a5.993 5.993 0 0 0 3-.8l16.8-9.7a3 3 0 0 0 1.087-4.1z"
        fill="#ffbcab"
      />
      <path d="m8 45h3v14h-3z" fill="#00479b" />
      <path
        d="m53.476 26.405a2.993 2.993 0 0 0 -.576-3.405 3.073 3.073 0 0 0 -4.242 0l-.706.707-1-1 1.6-1.6a3.111 3.111 0 0 1 2.717-.814 9.074 9.074 0 0 0 7.706-2.538 8.974 8.974 0 0 0 1.925-9.876 1 1 0 0 0 -1.628-.318l-4.872 4.868-2.356-.471-.473-2.358 4.868-4.869a1 1 0 0 0 -.318-1.631 9 9 0 0 0 -12.415 9.634 3.123 3.123 0 0 1 -.815 2.714l-1.6 1.6-6.662-6.663-1.346-2.685a1 1 0 0 0 -.408-.427l-6.359-3.527a1 1 0 0 0 -1.193.167l-1.41 1.41a1 1 0 0 0 -.167 1.192l3.53 6.36a1 1 0 0 0 .426.409l2.686 1.347 6.661 6.662-1.6 1.6a3.111 3.111 0 0 1 -2.717.814 9.006 9.006 0 0 0 -9.632 12.414 1 1 0 0 0 1.628.318l4.87-4.868 2.356.471.471 2.357-4.868 4.869a1 1 0 0 0 .318 1.628 9 9 0 0 0 12.415-9.634 3.123 3.123 0 0 1 .815-2.714l1.6-1.6 1 1-.705.709a2.992 2.992 0 0 0 3.407 4.821l5.906 5.907a5 5 0 1 0 7.072-7.071zm-21.789-13.305a.99.99 0 0 0 -.258-.187l-2.554-1.281-3.015-5.432.34-.34 5.431 3.015 1.281 2.553a.972.972 0 0 0 .187.259l6.776 6.777-1.416 1.415zm8.013 14.033a5.1 5.1 0 0 0 -1.379 4.426 7.005 7.005 0 0 1 -8.16 7.941l4.066-4.065a1 1 0 0 0 .273-.9l-.712-3.535a1 1 0 0 0 -.788-.788l-3.535-.707a1 1 0 0 0 -.9.273l-4.065 4.065a7.006 7.006 0 0 1 7.938-8.16 5.167 5.167 0 0 0 4.428-1.378l7.439-7.438a5.1 5.1 0 0 0 1.379-4.426 7.005 7.005 0 0 1 8.16-7.941l-4.066 4.065a1 1 0 0 0 -.273.9l.707 3.535a1 1 0 0 0 .784.784l3.535.707a1 1 0 0 0 .9-.273l4.069-4.061a7.006 7.006 0 0 1 -7.938 8.16 5.164 5.164 0 0 0 -4.428 1.383zm5.842-3.012 1 1-1.422 1.415-1-1zm-1.123 7.364a1 1 0 0 1 0-1.414l5.657-5.657a1 1 0 1 1 1.414 1.415l-5.658 5.657a1.023 1.023 0 0 1 -1.418-.001zm13.557 6.486a3.005 3.005 0 0 1 -4.243 0l-5.779-5.779 4.243-4.242 5.779 5.778a3 3 0 0 1 0 4.243z"
      />
      <path
        d="m61.148 44.9a3.995 3.995 0 0 0 -5.458-1.469l-9.751 5.629a3.964 3.964 0 0 0 -2.259-1.629l-14.92-4a7 7 0 0 0 -3.51-.03l-5.25 1.318v-1.719a1 1 0 0 0 -1-1h-8a1 1 0 0 0 -1 1v1h-7a1 1 0 0 0 -1 1v14a1 1 0 0 0 1 1h7v1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1.382l1.286.643a7.027 7.027 0 0 0 3.13.739h14.977a7.011 7.011 0 0 0 3.5-.938l16.8-9.7a4.011 4.011 0 0 0 1.455-5.462zm-57.148 13.1v-12h6v12zm14 2h-6v-16h6zm40.69-11.366-16.8 9.7a5 5 0 0 1 -2.5.67h-14.974a5.027 5.027 0 0 1 -2.235-.528l-2.181-1.094v-10.6l5.734-1.434a5.025 5.025 0 0 1 2.508.021l14.92 4a2 2 0 1 1 -1.035 3.863l-11.927-3.198a1 1 0 0 0 -.517 1.932l11.925 3.2a4.043 4.043 0 0 0 1.042.138 3.952 3.952 0 0 0 3.965-4.317l10.072-5.815a2.035 2.035 0 0 1 2.726.732 2.008 2.008 0 0 1 -.723 2.73z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'maintenance-icon',
}
</script>
